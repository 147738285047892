// src/components/ContactPage.js
import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

import "./ContactPage.css"; // Make sure to create this CSS file
const ContactPage = () => {
  const handleCall = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`, "_blank");
  };
  return (
    <Container className="contact-container">
      <Row>
        <Alert variant="warning">Under Construction</Alert>
        <Col md={6} className="image-section">
          <Image
            src="https://th.bing.com/th/id/R.655ea667bb77d640cdd451c6a37d34bb?rik=f7EqJZ%2fEZnI6JA&pid=ImgRaw&r=0"
            alt="Under Construction"
            fluid
          />
        </Col>

        <Col md={6} className="contact-section">
          <div className="office">
            <h2>Sapphire General Trading LLC</h2>
            <h4>Dubai Office</h4>
            <p>
              Office No: 315, 3rd Floor, Bank Street Building, Khalid Bin Waleed
              Road
            </p>
            <p> BurDubai, Dubai UAE P.O. BOX 45195</p>
            <p>Email: Umar@sapphiretradingllc.com</p>
            <p
              onClick={() => handleCall("+97142688750")}
              style={{ cursor: "pointer", color: "#007bff" }}
            >
              Tel: +971 4 2688750
            </p>
            <p
              onClick={() => handleCall("+971523238265")}
              style={{ cursor: "pointer", color: "#007bff" }}
            >
              Tel: +971 52 3238265
            </p>
          </div>
          <div className="office">
            <h2>Pakistan Office</h2>
            <h4>Pacific Trade Impex</h4>
            <p>
              Address: Office# 3, 2nd floor Birdwood luxury Appartments,11
              Birdwood Road
            </p>
            <p>Lahore-Pakistan 54000</p>
            <p>Tel: (92) 42 37424129-37426446</p>

            <p
              onClick={() => handleCall("+933338444811")}
              style={{ cursor: "pointer", color: "#007bff" }}
            >
              Mobile: +93 333 8444811
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactPage;
